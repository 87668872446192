import { Avatar, Button, Card, Divider, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from '../css/UI.module.css';

const SectionProfileEdit=() =>{
    const navigate = useNavigate();
    return(
        <Card>            
            <Stack padding='10px' alignItems='center' spacing={6}>
                <div className={classes.labelTitleSecondary}>Редактирование профиля</div>
                <TextField className={classes.textFieldMain} label='Имя' helperText='Введите имя' variant='filled' size='small' color='info'></TextField> 
                <TextField className={classes.textFieldMain} label='Дата рождения' helperText='Введите дату рождения' variant='filled' size='small' color='info'></TextField> 
                <TextField className={classes.textFieldMain} label='Город' helperText='Введите название города' variant='filled' size='small' color='info'></TextField> 
                <TextField className={classes.textFieldMain} label='Профессия' helperText='Введите профессию' variant='filled' size='small' color='info'></TextField> 
                <TextField className={classes.textFieldMain} label='О себе' helperText='Введите информацию о себе' variant='filled' size='small' color='info'></TextField> 
                <Avatar sx={{ width: 128, height: 128 }}></Avatar>
                <Button onClick={() => navigate('/main')} variant='contained' color='info' className={classes.btnMain}>Применить</Button>
            </Stack>
        </Card>        
        );
};

export default SectionProfileEdit;