import {FunctionComponent} from 'react';
import { Avatar, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from '../css/UI.module.css';

interface PopularRowProps{
    account : string;
    profession : string;
    icon? : any;
}

const RowPopularToSection: FunctionComponent<PopularRowProps> =  
    ({
        account, 
        profession,
        icon
    }) => {
    const navigate = useNavigate();
    return(        
        <Stack direction='row' alignContent='center' justifyContent='space-between'>
            <Stack spacing={0}>
                <Button onClick={() => navigate('/profile')} variant='text' color='info' className={classes.btnActual} style={{justifyContent:'flex-start', textTransform:'capitalize', padding:'5px'}}>{account}</Button> 
                <div className={classes.labelActualSmall}>{profession}</div>   
            </Stack>
            <Avatar sx={{ width: 36, height: 36 }}>{icon}</Avatar>
        </Stack>
        );

};

export default RowPopularToSection;