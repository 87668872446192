import {FunctionComponent} from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button, Card, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from '../css/UI.module.css';

interface NewsRowProps{
    account : string;
    time : string;
    publication : string;
}

const RowCommentsToSection: FunctionComponent<NewsRowProps> =  
    ({
        account, 
        time,
        publication  
    }) => {
    const navigate = useNavigate();
    return(
        <Stack>
            <Stack spacing={1}>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Button onClick={() => navigate('/profile')} variant='text' color='inherit' style={{justifyContent:'flex-start', fontWeight:'bold', textTransform:'capitalize', padding:'5px'}}>{account}</Button> 
                    <div className={classes.labelNewsOther}>{time}</div>
                </Stack>
                <div className={classes.labelNewsMessage}>{publication}</div>               
            </Stack>     
        </Stack>               
        );
};

export default RowCommentsToSection;