import SectionChooseSign from '../SectionChooseSign';
import classes from '../../css/PageMain.module.css'


const PageSign=() =>{  
    return(
        <div className={classes.bodyMain}>
            <div className={classes.centered}>
                <SectionChooseSign></SectionChooseSign>                  
            </div>                           
        </div>         
    );
};

export default PageSign;