import { Card, Stack } from '@mui/material';
import classes from '../css/UI.module.css';

const SectionAdvert=() =>{
    return(
        <Card>  
            <Stack style={{padding:'10px'}} spacing={1}>                
                <div className={classes.labelTitleSecondary}>Реклама</div>                           
            </Stack>    
        </Card>        
        );
};

export default SectionAdvert;