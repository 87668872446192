import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageComments from './components/pages/PageComments';
import PageMain from './components/pages/PageMain';
import PageMessage from './components/pages/PageMessage';
import PageProfileEdit from './components/pages/PageProfileEdit';
import PageSearch from './components/pages/PageSearch';
import PageSign from './components/pages/PageSign';

const App = () => {    
  return ( 
      <div> 
        <Routes>
          <Route path="/" element={<PageSign />} />
          <Route path="/main" element={<PageMain />} />
          <Route path="/profile" element={<PageMessage />} />
          <Route path="/edit" element={<PageProfileEdit />} />          
          <Route path="/search" element={<PageSearch />} />
          <Route path="/comments" element={<PageComments />} />
        </Routes>
      </div>    
  );
};

export default App;