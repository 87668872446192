import NavigationBarUp from '../NavigationBarUp';
import SectionProfile from '../SectionProfile';
import SectionActual from '../SectionActual';
import SectionAdvert from '../SectionAdvert';
import SectionPopular from '../SectionPopular';
import SectionNews from '../SectionNews';
import { Grid, Stack } from '@mui/material';
import classes from '../../css/PageMain.module.css'

const PageMain=() =>{
    return(
        <div className={classes.bodyMain}>
            <Stack width='100%' alignItems={'center'}>
                <Grid width='90%' height='100%' container spacing={1}>
                    <Grid height='10%' item xs={12}>
                        <NavigationBarUp></NavigationBarUp>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack spacing={1}>
                            <SectionProfile></SectionProfile>  
                            <SectionActual></SectionActual>
                        </Stack>                      
                    </Grid>
                    <Grid item xs={6}>
                        <SectionNews></SectionNews>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack spacing={1}>
                            <SectionAdvert></SectionAdvert>
                            <SectionPopular></SectionPopular>    
                        </Stack>                        
                    </Grid>             
                </Grid>    
            </Stack>          
        </div>      
    );
};

export default PageMain;