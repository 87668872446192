import test from'../images/test.jpg';
import { Button, Card, Divider, IconButton, Stack, TextField } from '@mui/material';
import RowCommentsToSection from './RowCommentsToSection';
import RowNewsToSection from './RowNewsToSection';
import AddCommentIcon from '@mui/icons-material/AddComment';
import classes from '../css/UI.module.css';

const SectionComments=() =>{
    return(
        <Card>            
            <Stack style={{padding:'10px'}} spacing={1}>  
                <div className={classes.labelTitleSecondary}>Лента новостей</div>                
                <RowNewsToSection account='user 2' time='10 лет' publication='Всем привет!' comment='12134' like='12847148' image={test} imgWidth={'60%'}></RowNewsToSection>
            </Stack>    
            <Stack style={{padding:'10px'}} spacing={1}>  
                <div className={classes.labelProfile}>Комментарии</div>                
                <RowCommentsToSection account='user 5' time='10 лет' publication='Всем привет!'></RowCommentsToSection>    
                <Divider></Divider>
                <RowCommentsToSection account='user 5' time='10 лет' publication='Всем привет!'></RowCommentsToSection>  
                <Divider></Divider>
                <TextField label='Комментарий' variant='outlined' size='small' color='info' multiline rows={4}></TextField>    
                <Stack alignItems='flex-end' justifyContent='flex-end'> 
                    <IconButton size='large' sx={{color:'#0288d1'}}><AddCommentIcon fontSize="large"/></IconButton>            
                </Stack>               
            </Stack>              
        </Card>        
        );
};
export default SectionComments;