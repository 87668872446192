import RowNewsToSection from './RowNewsToSection';
import { Card, Divider, Stack } from '@mui/material';
import classes from '../css/UI.module.css';

const SectionMessage=() =>{
    return(
        <Card> 
            <Stack style={{padding:'10px'}} spacing={1}>                
                <div className={classes.labelTitleSecondary}>Публикации</div>
                <RowNewsToSection account='user' time='10 лет' publication='Всем привет!' comment='1' like='5'></RowNewsToSection>
                <Divider />
                <RowNewsToSection account='user' time='10 лет' publication='Всем привет!' comment='1' like='5'></RowNewsToSection>                           
            </Stack>               
        </Card>        
        );
};
export default SectionMessage;