import RowPopularToSection from './RowPopularToSection'
import { Card, Divider, Stack } from '@mui/material';
import classes from '../css/UI.module.css';

const SectionSearch=() =>{
    return(
        <Card>            
            <Stack padding='10px' spacing={1}>
                <div className={classes.labelTitleSecondary}>Список пользователей</div>
                <RowPopularToSection account='Петров Иван Владимирович' profession='Инженер'></RowPopularToSection>
                <Divider/>
                <RowPopularToSection account='Иванов Иван' profession='Повар'></RowPopularToSection>               
            </Stack>   
        </Card>        
        );
};

export default SectionSearch;