import { Avatar, Button, Card, IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import classes from '../css/UI.module.css';
import { useNavigate } from 'react-router-dom';

const SectionProfile=() =>{
    const navigate = useNavigate();
    return(
        <Card>
            <Stack style={{background:'#0288d1'}} padding='10px' spacing={1}>
                <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
                    <div className={classes.labelProfileSecondary}>онлайн</div>
                    <IconButton onClick={() => navigate('/edit')} size='medium' sx={{color:'#FFFFFF'}}><EditIcon fontSize="medium"/></IconButton>                     
                </Stack>
                <div className={classes.labelProfilePrimary}>Иванов Иван Иванович</div>
                <div className={classes.labelProfilePrimary}>25 лет</div>  
                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>                    
                    <Stack spacing={1}>
                        <div className={classes.labelProfileSecondary}>Программист</div>
                        <div className={classes.labelProfileSecondary}>Россия, Казань</div>   
                        <div className={classes.labelProfileSecondary}>Дата рождения 12.05.2000</div> 
                        <div className={classes.labelProfileSecondary}>О себе: люблю готовить</div>
                    </Stack>                                         
                    <Avatar variant='rounded' sx={{ width: 144, height: 144 }}></Avatar>      
                </Stack>                 
            </Stack>  
            <Stack padding='10px' spacing={0}>   
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Button onClick={() => navigate('/profile')} variant='text' color='info' className={classes.btnActual} style={{justifyContent:'flex-start', textTransform:'capitalize'}}>Публикации</Button> 
                    <div className={classes.labelProfile}>11058</div>  
                </Stack>   
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Button onClick={() => navigate('/search')} variant='text' color='info' className={classes.btnActual} style={{justifyContent:'flex-start', textTransform:'capitalize'}}>Подписки</Button> 
                    <div className={classes.labelProfile}>121123</div>  
                </Stack>  
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Button onClick={() => navigate('/search')} variant='text' color='info' className={classes.btnActual} style={{justifyContent:'flex-start', textTransform:'capitalize'}}>Подписчики</Button> 
                    <div className={classes.labelProfile}>1456456</div>  
                </Stack>      
            </Stack>   
        </Card>        
        );
};

export default SectionProfile;