import {FunctionComponent} from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button, Card, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from '../css/UI.module.css';

interface NewsRowProps{
    account : string;
    time : string;
    publication : string;
    image? : any;
    comment : string;
    like : string;  
    imgWidth? : string; 
    imgHeight? : string; 
}

const RowNewsToSection: FunctionComponent<NewsRowProps> =  
    ({
        account, 
        time,
        publication,
        image,
        comment,
        like,
        imgWidth,
        imgHeight       
    }) => {
    const navigate = useNavigate();
    return(
        <Card variant='elevation' style={{padding:'10px'}}>
            <Stack spacing={1}>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Button onClick={() => navigate('/profile')} variant='text' color='inherit' style={{justifyContent:'flex-start', fontWeight:'bold', textTransform:'capitalize', padding:'5px'}}>{account}</Button> 
                    <div className={classes.labelNewsOther}>{time}</div>
                </Stack>
                <div className={classes.labelNewsMessage}>{publication}</div>
                <Stack alignItems='center'>
                    <img width={imgWidth} height={imgHeight} src={image}/>
                </Stack> 
                <Stack direction='row' spacing={2}>               
                    <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={0.1}>               
                        <IconButton onClick={() => navigate('/comments')} size='small' sx={{color:'#0288d1'}}><CommentIcon fontSize="small"/></IconButton>
                        <div className={classes.labelNewsOther}>{comment}</div>   
                    </Stack>
                    <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={0.1}>
                        <IconButton size='small' sx={{color:'#d50000'}}><FavoriteBorderIcon fontSize="small"/></IconButton>
                        <div className={classes.labelNewsOther}>{like}</div>
                    </Stack>
                </Stack>
            </Stack>     
        </Card>               
        );
};

export default RowNewsToSection;