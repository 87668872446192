import RowNewsToSection from './RowNewsToSection';
import test from'../images/test.jpg';
import { Card, Stack } from '@mui/material';
import classes from '../css/UI.module.css';

const SectionNews=() =>{
    return(
        <Card>            
            <Stack style={{padding:'10px'}} spacing={1}>  
                <div className={classes.labelTitleSecondary}>Лента новостей</div>                
                <RowNewsToSection account='user 2' time='10 лет' publication='Всем привет!' comment='12134' like='12847148' image={test} imgWidth={'60%'}></RowNewsToSection>
                <RowNewsToSection account='user 5' time='10 лет' publication='Всем привет!' comment='1' like='5'></RowNewsToSection>                
            </Stack>              
        </Card>        
        );
};

export default SectionNews;