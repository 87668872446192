import RowActualToSection from './RowActualToSection'
import { Card, Divider, Stack } from '@mui/material';
import classes from '../css/UI.module.css';

const SectionActual=() =>{
    return(
        <Card>            
            <Stack style={{padding:'10px'}} spacing={1}>                
                <div className={classes.labelTitleSecondary}>Актуальное</div>
                <RowActualToSection account='IT в Казани' publication='11120 публикаций'></RowActualToSection>
                <Divider/>
                <RowActualToSection account='IT в мире' publication='2341211120 публикаций'></RowActualToSection>               
            </Stack>   
        </Card>        
        );
};

export default SectionActual;