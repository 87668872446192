import mainLogo from'../images/Bear-32.png';
import CreateIcon from '@mui/icons-material/Create';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import GifBoxIcon from '@mui/icons-material/GifBox';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import SendIcon from '@mui/icons-material/Send';
import classes from '../css/UI.module.css';

const NavigationBarUp=() =>{
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    return(
        <div>
            <Card>
                <Grid container alignItems='center' padding='10px' spacing={1}>
                    <Grid item xs={4}>
                        <Stack direction='row' justifyContent='flex-start' spacing={18}>
                            <Button startIcon={<CloseIcon />} onClick={() => navigate('/')} variant='text' color='info'>Выход</Button>
                            <Button startIcon={<HomeIcon />} onClick={() => navigate('/main')} variant='text' color='info'>Главная</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction='row' justifyContent='center'>
                            <img src={mainLogo}/> 
                        </Stack>                                          
                    </Grid> 
                    <Grid item xs={4}>
                        <Stack direction='row' justifyContent='flex-end' alignContent='center' spacing={1}>
                            <TextField className={classes.textFieldSearch} size='small' variant='outlined' color='info' label='Поиск'></TextField>
                            <IconButton onClick={() => navigate('/search')} size='medium' sx={{color:'#0288d1'}}><SearchIcon fontSize="medium"/></IconButton>
                            <IconButton onClick={() => setOpen(true)} size='medium' sx={{color:'#0288d1'}}><CreateIcon fontSize="medium"/></IconButton>
                        </Stack>              
                    </Grid>        
                </Grid>  
            </Card>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">                              
                    <DialogTitle>
                        {"Новая публикация"}
                    </DialogTitle>
                    <DialogContent style={{padding:'10px'}}>
                        <Stack alignContent='flex-start' justifyContent='flex-start' spacing={2}>  
                            <TextField className={classes.textFieldMessage} label='Что нового?' variant='outlined' size='small' color='info' multiline rows={4}></TextField>
                            <Stack direction='row' justifyContent='space-between'>
                                <Stack direction='row' width='300px'>
                                    <IconButton size='large' sx={{color:'#0288d1'}}><ImageIcon fontSize="large"/></IconButton>
                                    <IconButton size='large' sx={{color:'#0288d1'}}><GifBoxIcon fontSize="large"/></IconButton>
                                    <IconButton size='large' sx={{color:'#0288d1'}}><MusicVideoIcon fontSize="large"/></IconButton>
                                </Stack>
                                <IconButton size='large' sx={{color:'#0288d1'}}><SendIcon fontSize="large"/></IconButton>
                            </Stack>
                        </Stack> 
                    </DialogContent>
            </Dialog>
        </div>
        );
};

export default NavigationBarUp;