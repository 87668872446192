import {FunctionComponent} from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from '../css/UI.module.css';

interface ActualRowProps{
    account : string;
    publication : string;
}

const RowActualToSection: FunctionComponent<ActualRowProps> =  
    ({
        account, 
        publication
    }) => {
    const navigate = useNavigate();
    return(        
        <Stack spacing={0}>
            <Button onClick={() => navigate('/profile')} variant='text' color='info' className={classes.btnActual} style={{justifyContent:'flex-start', textTransform:'capitalize', padding:'5px'}}>{account}</Button> 
            <div className={classes.labelActualSmall}>{publication}</div>   
        </Stack>        
        );
};

export default RowActualToSection;