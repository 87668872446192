import mainLogo from'../images/Bear-64.png';
import { Button, Card, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classes from '../css/UI.module.css'

const SectionChooseSign=() =>{   
    const [openReg, setOpenReg] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const navigate = useNavigate();
    return(            
        <div>
            <Card>
                <Stack padding='50px' alignContent='flex-start' justifyContent='flex-start' spacing={1}>
                    <img width={'20%'} src={mainLogo}/>
                    <p className={classes.labelTitlePrimary}>Узнайте что происходит в России прямой сейчас</p><br/>  
                    <p className={classes.labelTitlePrimarySmall}>А ты еще не с нами?</p><br/>                           
                    <Button onClick={() => setOpenReg(true)} variant='contained' color='info' className={classes.btnMain}>Регистрация</Button>
                    <Button onClick={() => setOpenAuth(true)} variant='outlined' color='info' className={classes.btnMain}>Вход</Button>
                </Stack> 
            </Card>             
            <Dialog
                open={openReg}
                onClose={() => setOpenReg(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">                              
                    <DialogTitle>
                        {"Регистрация"}
                    </DialogTitle>
                    <DialogContent>
                        <Stack alignContent='flex-start' justifyContent='flex-start' spacing={6}>  
                            <TextField className={classes.textFieldMain} label='Имя' helperText='Введите имя' variant='filled' size='small' color='info'></TextField>
                            <TextField className={classes.textFieldMain} label='Почта' helperText='Введите почту' variant='filled' size='small' color='info'></TextField>
                            <TextField className={classes.textFieldMain} label='Логин' helperText='Введите логин' variant='filled' size='small' color='info'></TextField>
                            <TextField className={classes.textFieldMain} label='Пароль' helperText='Введите пароль' variant='filled' size='small' color='info'></TextField>
                            <Button onClick={() => navigate('/main')} variant='outlined' color='info' className={classes.btnMain}>Создать</Button>
                        </Stack> 
                    </DialogContent>   
            </Dialog>
            <Dialog
                open={openAuth}
                onClose={() => setOpenAuth(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">                              
                    <DialogTitle>
                        {"Авторизация"}
                    </DialogTitle>
                    <DialogContent>
                        <Stack alignContent='flex-start' justifyContent='flex-start' spacing={6}>  
                            <TextField className={classes.textFieldMain} label='Логин' helperText='Введите логин' variant='filled' size='small' color='info'></TextField>
                            <TextField className={classes.textFieldMain} label='Пароль' helperText='Введите пароль' variant='filled' size='small' color='info'></TextField>
                            <Button onClick={() => navigate('/main')} variant='outlined' color='info' className={classes.btnMain}>Войти</Button>
                        </Stack> 
                    </DialogContent>   
            </Dialog>
        </div>
        );
};

export default SectionChooseSign;