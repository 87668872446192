import NavigationBarUp from '../NavigationBarUp';
import SectionAdvert from '../SectionAdvert';
import { Grid, Stack } from '@mui/material';
import SectionProfile from '../SectionProfile';
import SectionMessage from '../SectionMessage';
import classes from '../../css/PageMain.module.css'

const PageMessage=() =>{
    return(
        <div className={classes.bodyMain}>
            <Stack width='100%' alignItems={'center'}>
                <Grid width='90%' height='100%' container spacing={1}>
                    <Grid height='10%' item xs={12}>
                        <NavigationBarUp></NavigationBarUp>
                    </Grid>
                    <Grid item xs={3}>
                        <SectionProfile></SectionProfile>                      
                    </Grid>
                    <Grid item xs={6}>
                        <SectionMessage></SectionMessage>
                    </Grid>
                    <Grid item xs={3}>
                        <SectionAdvert></SectionAdvert>                      
                    </Grid>             
                </Grid>    
            </Stack>          
        </div>      
    );
};

export default PageMessage;